export const getWorkspaceFromDropdown = () => {
    let wsid = "";
    if (typeof(window) != 'undefined') {
      let elem = document.getElementById('workspace_dropdown');
      wsid = elem.value;
      if (wsid.toLowerCase() == "default") {
        wsid = "";
      }
    }
    return wsid;
}