export const useTaskStore = defineStore("TaskStore", {
  state: () => ({
    task: {},
    tasks: [],
    tags: [],
    codeEditorTabs: [],
    chatVisible: true,
    codeEditorVisible: false,
    activeTab: {},
    showCollaborationModal: false,
    showSubmitToSpaces: false,
    notifications: null,
    notifications_for_currently_selected_space: null,
    rightPaneWidth: 0,
    task_save_from_tab: null
  }),
  getters: {
    getTask() {
      if (! this.task.hasOwnProperty('workspace_status')) {
        this.task['workspace_status'] = {};
      }
      return this.task;
    },
    getTasks() {
      return this.tasks;
    },
    getTags() {
      return this.tags;
    },
    getCodeEditorTabs() {
      return this.codeEditorTabs;
    },
    getActiveTab(){
      return this.activeTab
    },
    getChatVisible() {
      return this.chatVisible;
    },
    getNotifications() {
      return this.notifications;
    },
    getNotificationsForCurrentlySelectedSpace() {
      return this.notifications_for_currently_selected_space;
    },
    getRightPaneWidth() {
      return this.rightPaneWidth;
    },
    getCodeEditorVisibility() {
      return this.codeEditorVisible;
    },
    getTaskSaveFromTab() {
      return this.task_save_from_tab;
    },
  },
  actions: {
    async fetchTask(taskId, taskCache) {
      this.task = await readTask(taskId, taskCache);
    },
    setTask(task) {
      this.task = task;
    },
    setTasks(task) {
      this.tasks.push(task);
    },
    setTag(tag) {
      this.tags.push(tag);
    },
    setAllTags(tags) {
      this.tags = tags;
    },
    setActiveTab(tab){
      this.activeTab = tab;
    },
    resetActiveTab() {
      this.activeTab = [];
    },
    removeAllTask() {
      this.tasks = [];
    },
    setChatVisible(status) {
      this.chatVisible = status;
    },
    setAllTabs(tabs){
      this.codeEditorTabs = tabs
    },
    setCodeEditorTabs(tabData){
      this.codeEditorTabs.push(tabData);
    },
    setCodeEditorVisibility(status) {
      this.codeEditorVisible = status
    },
    setNotification(notifications) {
      this.notifications = notifications;
    },
    setCollaborationModalState(status) {
      this.showCollaborationModal = status
    },
    setSubmitToSpacesState(status) {
      this.showSubmitToSpaces = status
    },
    setRightPaneWidth(width) {
      this.rightPaneWidth = width;
    },
    removeCodeEditorTabs(tabId){
      this.codeEditorTabs = this.codeEditorTabs.filter(tab => tab.id !== tabId);
    },
    removeTask(taskId){
      this.tasks = this.tasks.filter(task => task.id !== taskId);
    },
    async fetchNotifications() {
      let result = await getNotifications()
      if (result) {
        let activeNotifications = []
        result.tasks.forEach(item => {
          if (item.hasOwnProperty('pending_permissions')) {
            if (Object.keys(item.pending_permissions).length > 0) {
              activeNotifications.push(item); // activeNotifications is an array of tasks
            }
          } 
        });

        let users = []
        let userIds = []
        activeNotifications.forEach((notification) => {
          let ids = (Object.keys(notification.pending_permissions))
          ids.forEach((id) => {
            users.push({taskId: notification.id, title: notification.title, userEmail: id}); // users is an array of objects with taskId, task title, and perhaps user ID or email, so the users array is the array of notifications
            userIds.push(id)
          })
        })

        if (userIds.length > 0) {
          let userInfo = await getUserInfo(userIds)
          this.notifications = users.map(user => ({
            ...user,
            ...userInfo[user.userEmail]
          }))
        } else {
          this.notifications = [];
        }
      }
    },
    async fetchNotificationsForCurrentlySelectedSpace(wsid, nuxtInstance) {
      this.notifications_for_currently_selected_space = []; // Clear out the previous result
      let result = await getNotificationsForCurrentlySelectedSpace(wsid);

      if (result) {
        let tasks_requiring_approval = [];
        result.tasks.forEach(item => {
          tasks_requiring_approval.push(item);
        });

        let notifications = [];
        let userIds = [];

        tasks_requiring_approval.forEach((task) => {
          if (task?.workspace_status?.[wsid]?.submitted_by) {
            userIds.push(task.workspace_status[wsid].submitted_by);

            notifications.push({
              taskId: task.id,
              title: task.title,
              user_id: task.workspace_status[wsid].submitted_by
            })
          }
        });


        if (userIds.length > 0) {
          let userInfo = undefined;
          if (nuxtInstance) {
            userInfo = await nuxtInstance.runWithContext(async () => {
              return await getUserInfo(userIds)
            });
          } else {
            userInfo = await getUserInfo(userIds);
          }

          this.notifications_for_currently_selected_space = notifications.map(notification => ({
            ...notification,
            ...userInfo[notification.user_id]
          }));
        } else {
          this.notifications_for_currently_selected_space = [];
        }
      }
    },
    setTaskSaveFromTab(id) {
      this.task_save_from_tab = id;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTaskStore, import.meta.hot));
}
